import "react-dates/initialize"
import React, { useState } from "react"
import { navigate } from "gatsby-link"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import styles from "./booking.module.scss"
import Calendar from "./calendar"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default props => {
  let [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    adults: props.values === undefined ? 2 : props.values.adults,
    children: props.values === undefined ? 0 : props.values.children,
    rooms: props.values === undefined ? 1 : props.values.rooms,
    checkIn: props.values === undefined ? false : props.values.checkIn,
    checkOut: props.values === undefined ? false : props.values.checkOut,
    request: "",
  })

  function handleEvent({ checkIn, checkOut }) {
    setValues({ ...values, checkIn: checkIn, checkOut: checkOut })
  }

  const handleInputChange = event => {
    setValues({
      ...values,
      [event.currentTarget.id]: event.currentTarget.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...values,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <Form
      className={styles.block}
      action="/thanks"
      data-netlify="true"
      netlify-honeypot="bot-field"
      method="post"
      name="availability"
    >
      <input type="hidden" name="form-name" value="availability" />
      <input type="hidden" name="bot-field" />
      <Form.Group controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          name="name"
          type="text"
          required
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          name="email"
          type="email"
          required
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="phone">
        <Form.Label>Phone</Form.Label>
        <Form.Control
          name="phone"
          type="tel"
          required
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="dateRange">
        <Form.Label>Checkin & Checkout</Form.Label>
        <div>
          <Calendar
            checkIn={values.checkIn}
            checkOut={values.checkOut}
            setDates={handleEvent}
          />
        </div>
      </Form.Group>
      <Form.Row>
        <Form.Group as={Col} controlId="rooms">
          <Form.Label>Rooms</Form.Label>
          <Form.Control
            as="select"
            required
            onChange={handleInputChange}
            value={values.rooms}
            name="rooms"
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="adults">
          <Form.Label>Adults</Form.Label>
          <Form.Control
            as="select"
            required
            onChange={handleInputChange}
            value={values.adults}
            name="adults"
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="children">
          <Form.Label>Children</Form.Label>
          <Form.Control
            as="select"
            required
            onChange={handleInputChange}
            value={values.children}
            name="children"
          >
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>

      <Form.Group controlId="request">
        <Form.Label>Questions and special requests</Form.Label>
        <Form.Control
          name="request"
          as="textarea"
          rows="3"
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formSubmit" className={styles.submit}>
        <Form.Control
          type="submit"
          className="btn btn-primary"
          value="Check Availability"
        />
      </Form.Group>
    </Form>
  )
}
