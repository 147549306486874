import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { DateRangePicker } from "react-dates"
import "react-dates/lib/css/_datepicker.css"
import Moment from "moment"

export default ({ checkIn, checkOut, setDates }) => (
  <StaticQuery
    query={graphql`
      query bookingsQuery {
        allMarkdownRemark(
          filter: { fields: { contentType: { eq: "bookings" } } }
        ) {
          totalCount
          nodes {
            id
            frontmatter {
              checkIn
              checkOut
            }
          }
        }
      }
    `}
    render={data => {
      const bookings = data.allMarkdownRemark.nodes.map(node => [
        node.frontmatter.checkIn,
        node.frontmatter.checkOut,
      ])

      const [values, setValues] = useState({
        checkIn: checkIn ? Moment(checkIn) : null,
        checkOut: checkOut ? Moment(checkOut) : null,
      })
      const [focusedInput, setFocusedInput] = useState(null)

      const isOutSideRange = day => {
        let booked = false
        bookings.forEach(booking => {
          if (day >= Moment(booking[0]) && day < Moment(booking[1])) {
            booked = true
          }
        })
        return booked
      }

      return (
        <DateRangePicker
          startDate={values.checkIn ? Moment(values.checkIn) : null}
          startDateId="check_in"
          endDate={values.checkOut ? Moment(values.checkOut) : null}
          endDateId="check_out"
          onDatesChange={({ startDate, endDate }) => {
            setValues({
              ...values,
              checkIn: startDate?.format("MM/DD/YYYY"),
              checkOut: endDate?.format("MM/DD/YYYY"),
            })
            setDates({
              checkIn: startDate?.format("MM/DD/YYYY"),
              checkOut: endDate?.format("MM/DD/YYYY"),
            })
          }}
          focusedInput={focusedInput}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          minDate={Moment()}
          maxDate={Moment().add(1, "year")}
          isDayBlocked={isOutSideRange}
          displayFormat={"DD/MM/YYYY"}
          minimumNights={3}
          startDatePlaceholderText={"Check-In"}
          endDatePlaceholderText={"Check-Out"}
          firstDayOfWeek={1}
          small
        />
      )
    }}
  />
)
